@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

@layer base {
  html,
  body,
  #root {
    @apply h-full;
  }

  input[type='text'],
  input[type='email'],
  input[type='password'],
  input[type='tel'],
  textarea,
  select {
    @apply block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary focus:outline-none focus:ring-primary sm:text-sm;
  }

  input[type='text'].invalid,
  input[type='email'].invalid,
  input[type='password'].invalid,
  input[type='tel'].invalid,
  textarea.invalid,
  select.invalid {
    @apply invalid:focus:border-red-500 invalid:border-red-500 invalid:focus:ring-red-500;
  }

  label {
    @apply block mb-1 text-sm font-medium text-gray-700;
  }
}

@layer components {
  .btn {
    @apply flex justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium shadow-sm;
  }

  .btn-primary {
    @apply bg-primary text-white;
  }
}

.StripeElement {
  @apply rounded-md border border-gray-300 px-3 py-2;
}

.StripeElement--focus {
  @apply border-primary outline-none ring-primary ring-2;
}
